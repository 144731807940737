<template>
  <v-chip-group v-if="chips">
    <v-chip
      v-for="sName in arNameList"
      :key="`currency.list.${sName}`"
      v-text="sName"
    />
  </v-chip-group>
  <span v-else v-text="arNameList.join(', ')" />
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { AppModule } from "@/store/app";
import { map } from "lodash";
import {
  Currency,
  CurrencyCollection,
  CurrencyData,
} from "@planetadeleste/vue-mc-shopaholic";

@Component
export default class CurrencyList extends Vue {
  @Prop(Array) readonly currencyIds!: number[];
  @Prop(Boolean) readonly chips?: boolean;

  selectedCurrencies: CurrencyData[] = [];

  get obCurrencies() {
    return AppModule.currencies;
  }

  get arNameList() {
    return this.selectedCurrencies.length
      ? map(this.selectedCurrencies, "name")
      : ["Todas"];
  }

  @Watch("currencyIds")
  selectCurrencies(): void {
    if (
      !this.currencyIds ||
      !this.currencyIds.length ||
      !this.obCurrencies.length
    ) {
      return;
    }

    const obCollection = this.obCurrencies.filter((obModel: Currency) =>
      this.currencyIds.includes(obModel.id)
    ) as CurrencyCollection;

    this.selectedCurrencies = obCollection.getModelList() as CurrencyData[];
  }

  mounted() {
    this.selectCurrencies();
  }
}
</script>
